<template>
  <div id="app">
    <v-app id="inspire">
      <div v-if="isUserLoggedIn && networkOnLine">
        <!-- <v-navigation-drawer v-model="drawerRight" app clipped right>
          <v-list dense>
            <v-list-item @click.stop="left = !left">
              <v-list-item-action>
                <v-icon>exit_to_app</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Open Temporary Drawer</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>-->

        <v-app-bar app clipped-right clipped-left color="dark-grey" dark>
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          <router-link to="/home">
            <v-toolbar-title>{{ appTitle }}</v-toolbar-title>
          </router-link>
          <v-spacer></v-spacer>
          <v-list dense>
            <v-list-item @click="logout">
              <v-list-item-content v-if="isUserLoggedIn && networkOnLine">
                <v-icon title="Logout">mdi-power</v-icon>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" app clipped left>
          <left-drawyer></left-drawyer>
        </v-navigation-drawer>

        <v-navigation-drawer v-model="left" fixed temporary></v-navigation-drawer>

        <v-main>
          <v-container fluid fill-height>
            <v-layout justify-center align-center>
              <router-view />
            </v-layout>
          </v-container>
        </v-main>

        <v-navigation-drawer v-model="right" fixed right temporary></v-navigation-drawer>
      </div>
      <div v-else>
        <login></login>
      </div>

      <new-content-available-toastr
        v-if="newContentAvailable"
        class="new-content-available-toastr"
        :refreshing-app="refreshingApp"
        @refresh="serviceWorkerSkipWaiting"
      ></new-content-available-toastr>

      <apple-add-to-home-screen-modal
        v-if="showAddToHomeScreenModalForApple"
        class="apple-add-to-home-screen-modal"
        @close="closeAddToHomeScreenModalForApple(false)"
      ></apple-add-to-home-screen-modal>
    </v-app>
  </div>
</template>

<script>
import LeftDrawyer from '@/components/LeftDrawyer'
import Login from '@/views/Login'
import NewContentAvailableToastr from '@/components/NewContentAvailableToastr'
/*import AppleAddToHomeScreenModal from '@/components/AppleAddToHomeScreenModal'*/
import { mapState, mapActions, mapGetters } from 'vuex'
import { desktop as isDekstop } from 'is_js'
import firebase from 'firebase/app'

export default {
  el: '#app',
  components: {
    NewContentAvailableToastr,
    Login,
    LeftDrawyer,
  },
  props: {
    source: String,
  },
  data: () => ({
    drawer: true,
    drawerRight: false,
    drawerLeft: true,
    right: false,
    left: false,
  }),
  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn']),
    ...mapGetters('app', ['newContentAvailable']),
    ...mapState('app', ['showAddToHomeScreenModalForApple', 'refreshingApp', 'networkOnLine', 'appTitle']),
    showDrawerIcon: () => {
      return !isDekstop()
    },
  },

  created() {
    this.$vuetify.theme.dark = true
  },
  methods: {
    ...mapActions('app', ['closeAddToHomeScreenModalForApple', 'serviceWorkerSkipWaiting']),
    async logout() {
      await firebase.auth().signOut()
    },
  },
}
</script>
<style lang="scss">
::-webkit-scrollbar {
  width: 0 !important;
}

body {
  margin: 0;
  a {
    font-weight: 500;
    text-decoration: none;
  }

  #app {
    .new-content-available-toastr {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }

    .apple-add-to-home-screen-modal {
      position: absolute;
      bottom: 0;
      right: 0;
      top: 0;
      left: 0;
      height: fit-content;
      width: fit-content;
      margin: auto;
      z-index: 1000;
    }

    .main-wrapper {
      margin-top: 3.6rem;
      padding: 20px;

      .page-wrapper {
        width: 60%;
        margin: auto;

        @media screen and (max-width: 1000px) {
          width: 100%;
        }
      }
    }
  }
}
</style>
