import UsersDB from '@/firebase/users-db'

export default {
  getUser: async ({ rootState, commit }, id) => {
    const userDb = new UsersDB(rootState.authentication.authUser.id)
    const dbuser = await userDb.read(id)
    commit('setRatingUser', dbuser || {})
  },

  /**
   * Create a user for current loggedin user
   */
  createUserUser: async ({ commit, rootState }, user) => {
    const userUserDb = new UsersDB(rootState.authentication.authUser.id)

    commit('setUserCreationPending', true)
    const createdUser = await userUserDb.create(user)
    commit('addUser', createdUser)
    commit('setUserCreationPending', false)
  },

  /**
   * Create a new user for current loggedin user and reset user name input
   */
  triggerAddUserAction: ({ dispatch, state, commit }) => {
    if (state.userNameToCreate === '') return

    const user = { name: state.userNameToCreate }
    commit('setUserNameToCreate', '')
    dispatch('createUserUser', user)
  },

  /**
   * Delete a user user from its id
   */
  deleteUserUser: async ({ rootState, commit, getters }, userId) => {
    if (getters.isUserDeletionPending(userId)) return

    const userUsersDb = new UsersDB(rootState.authentication.authUser.id)

    commit('addUserDeletionPending', userId)
    await userUsersDb.delete(userId)
    commit('removeUserById', userId)
    commit('removeUserDeletionPending', userId)
  },

  detachFromRating: ({ commit }) => {
    commit('setRatingUser', null)
  },
}
