export default {
  setRatingNow: (state, ratingNowRatings) => {
    state.ratingNow = ratingNowRatings
  },
  setMyRatings: (state, userRatings) => {
    state.myRatings = userRatings
  },
  setMyVotes: (state, votes) => {
    state.myVotes = votes
  },
}
