import RealtimeDb from '@/firebase/rt-db'
import UsersDB from '@/firebase/users-db'
import * as _ from 'lodash'

let ratingRef

export default {
  attachToRating: async ({ rootState, commit }, { idtarget }) => {
    const rtdb = new RealtimeDb('/ratings')
    ratingRef = await rtdb.attach(`${idtarget}/${rootState.authentication.authUser.id}`, data =>
      commit('setRatingNow', data)
    )
  },

  detachFromRating: ({ commit }) => {
    commit('setRatingNow', {})
    ratingRef.off('value')
  },

  attachMyRatings: ({ rootState, commit }) => {
    const rtdb = new RealtimeDb('/ratings')
    rtdb.attach(`${rootState.authentication.authUser.id}`, data => commit('setMyRatings', data))
  },

  attachMyVotes: ({ rootState, commit }) => {
    const rtdb = new RealtimeDb('/voters')
    const userDb = new UsersDB(rootState.authentication.authUser.id)
    rtdb.attach(`${rootState.authentication.authUser.id}`, async data => {
      const votes = new Set()
      for (const user of Object.keys(data)) {
        const read = await userDb.read(user)
        votes.add(read)
      }
      commit('setMyVotes', _.orderBy(Array.from(votes), ['displayName'], ['asc']))
    })
  },
  // addRate: ({}, { idtarget, idvoter }) => {
  //   const rtdb = new RealtimeDb('/ratings')

  //   rtdb.addChild('/ratings').push({ id: idtarget, [idvoter]: [] })
  // },

  // eslint-disable-next-line no-empty-pattern
  rate: async ({ rootState, dispatch }, { idrating, idtarget, rating, oldRating }) => {
    if (oldRating === rating) return dispatch('unrate', { idrating, idtarget })
    const rtdb = new RealtimeDb('/ratings')
    const updates = {}
    const idvoter = rootState.authentication.authUser.id
    updates[`/ratings/${idtarget}/${idvoter}/${idrating}`] = rating
    updates[`/voters/${idvoter}/${idtarget}/${idrating}`] = rating
    rtdb.update(updates)
  },

  unrate: async ({ rootState }, { idrating, idtarget }) => {
    const rtdb = new RealtimeDb('/ratings')
    const idvoter = rootState.authentication.authUser.id
    const updates = {}
    updates[`/ratings/${idtarget}/${idvoter}/${idrating}`] = 0
    updates[`/voters/${idvoter}/${idtarget}/${idrating}`] = 0
    rtdb.update(updates)
    await rtdb.removeChild(`/ratings/${idtarget}/${idvoter}/${idrating}`)
    await rtdb.removeChild(`/voters/${idvoter}/${idtarget}/${idrating}`)
  },
}
