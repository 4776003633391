import { find } from 'lodash'

export default {
  /**
   * Check if a trait has deletion pending
   */
  isTraitDeletionPending: state => traitId => state.traitDeletionPending.includes(traitId),

  /**
   * Get trait by id
   */
  getTraitById: state => traitId => find(state.traits, trait => trait.id === traitId),
}
