import state from './ratings.state'
import mutations from './ratings.mutations'
import actions from './ratings.actions'
//import getters from './ratings.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  //getters
}
