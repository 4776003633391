import { isNil, keys } from 'lodash'
import firebase from 'firebase/app'

import asyncfb from './async-firebase'

export default class RealtimeDb {
  constructor(collectionPath) {
    this.collectionPath = collectionPath
    this.firebase = asyncfb
  }

  /**
   * Create a document in the collection
   * @param data
   * @param id
   */
  // async create(data, id = null) {
  //   const collectionRef = (await firestore()).collection(this.collectionPath)
  //   const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()

  //   const dataToCreate = {
  //     ...data,
  //     createTimestamp: serverTimestamp,
  //     updateTimestamp: serverTimestamp
  //   }

  //   const createPromise = isNil(id)
  //     ? // Create doc with generated id
  //       collectionRef.add(dataToCreate).then(doc => doc.id)
  //     : // Create doc with custom id
  //       collectionRef
  //         .doc(id)
  //         .set(dataToCreate)
  //         .then(() => id)

  //   const docId = await createPromise

  //   return {
  //     id: docId,
  //     ...data,
  //     createTimestamp: new Date(),
  //     updateTimestamp: new Date()
  //   }
  // }

  async attachToRoot(handler) {
    const collectionRef = await asyncfb().ref(`${this.collectionPath}`)
    collectionRef.on('value', snapshot => {
      const data = snapshot.val()

      if (!isNil(data)) {
        handler({
          ...this.convertObjectTimestampPropertiesToDate(data),
        })
      }
    })
  }

  /**
   * Read a document in the collection
   * @param path
   */
  async attach(path, handler) {
    const ratingRef = await asyncfb().ref(`${this.collectionPath}/${path}`)
    ratingRef.on('value', snapshot => {
      const data = snapshot.val()

      if (!isNil(data)) {
        handler(this.convertObjectTimestampPropertiesToDate(data))
      }
    })

    return ratingRef
  }

  async addChild(root, data) {
    return this.firebase()
      .ref()
      .child(root)
      .push(data).key
  }

  async removeChild(path) {
    return this.firebase()
      .ref()
      .child(path)
      .remove()
  }

  async update(updates) {
    return this.firebase()
      .ref()
      .update(updates)
  }

  /**
   * Read all documents in the collection following constraints
   * @param constraints
   */
  async readAll(path, constraints = null) {
    const collectionRef = (await asyncfb()).collection(`${this.collectionPath}`)
    let query = collectionRef

    if (constraints) {
      constraints.forEach(constraint => (query = query.where(...constraint)))
    }

    const formatResult = result =>
      result.docs.map(ref =>
        this.convertObjectTimestampPropertiesToDate({
          id: ref.id,
          ...ref.data(),
        })
      )

    return query.get().then(formatResult)
  }

  // /**
  //  * Update a document in the collection
  //  * @param data
  //  */
  // async update(data) {
  //   const id = data.id
  //   const clonedData = cloneDeep(data)
  //   delete clonedData.id

  //   await (await asyncfb())
  //     .collection(this.collectionPath)
  //     .doc(id)
  //     .update({
  //       ...clonedData,
  //       updateTimestamp: firebase.firestore.FieldValue.serverTimestamp()
  //     })

  //   return id
  // }

  // /**
  //  * Delete a document in the collection
  //  * @param id
  //  */
  // async delete(id) {
  //   return (await asyncfb())
  //     .collection(this.collectionPath)
  //     .doc(id)
  //     .delete()
  // }

  /**
   * Convert all object Timestamp properties to date
   * @param obj
   */
  convertObjectTimestampPropertiesToDate(obj) {
    keys(obj)
      .filter(prop => obj[prop] instanceof Object)
      .forEach(prop => {
        // eslint-disable-next-line no-unused-expressions
        obj[prop] instanceof firebase.firestore.Timestamp
          ? // eslint-disable-next-line no-param-reassign
            (obj[prop] = obj[prop].toDate())
          : this.convertObjectTimestampPropertiesToDate(obj[prop])
      })
    return obj
  }
}
