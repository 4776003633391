import firebase from 'firebase/app'
import 'firebase/database'
import { isNil } from 'lodash'

let asyncFirebase = null

// Lazy load firestore with async import is important for performance

export default () => {
  if (isNil(asyncFirebase)) {
    asyncFirebase = firebase.database()
  }

  return asyncFirebase
}
