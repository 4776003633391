<template>
  <v-sheet>
    <v-card raised>
      <div class="overline pa-5">{{ appTitle }}</div>
      <v-card-title>
        "Even when one sits in the prisoner's dock, it is interesting to hear talk about oneself."
      </v-card-title>
      <v-card-subtitle>Albert Camus (1913-1960), French-Algerian. The Stranger, p. 139</v-card-subtitle>
    </v-card>
    <v-divider></v-divider>
    <v-card raised>
      <v-card-text>
        Do you ever wanted to tell someone something that would break your friendship?
      </v-card-text>
    </v-card>
    <v-card raised>
      <v-card-text>
        Do you ever wanted honest, true, feedback to improve your weaknesses?
      </v-card-text>
    </v-card>
    <v-card raised>
      <v-card-text>
        WeakSuit assumes positive intent: Give meaningfull and true feedback and improve someone else's behaviour!
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
import { mapState } from 'vuex'

export default {
  head: function() {
    return {
      title: {
        inner: 'Home',
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc',
        },
      ],
    }
  },
  computed: mapState('app', ['appTitle']),
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    margin-bottom: 3rem;
  }

  .home-page-title {
    text-align: center;
  }

  .documentation-link {
    display: inline-block;
    font-size: 1.2rem;
    color: #fff;
    background-color: #5d6788;
    padding: 0.8rem 1.6rem;
    border-radius: 4px;
    transition: background-color 0.1s ease;
    box-sizing: border-box;
    text-decoration: none;
    width: fit-content;
    font-weight: 500;
  }
}
</style>
