<template>
  <v-list v-if="isUserLoggedIn && networkOnLine" dense>
    <v-list-item>
      <v-list-item-avatar>
        <v-img :src="authUser.photoURL"></v-img>
      </v-list-item-avatar>
    </v-list-item>
    <v-list-item link>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{ authUser.displayName }}
        </v-list-item-title>
        <v-list-item-subtitle>{{ authUser.email }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <!-- <v-icon>mdi-menu-down</v-icon> -->
        <v-icon>mdi-chart-bubble</v-icon>
      </v-list-item-action>
    </v-list-item>
    <!-- <v-list-item>
      <v-list-item-content v-if="isUserLoggedIn && networkOnLine">
        <v-list-item-title>
          <router-link to="/rate">Rate</router-link>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>-->
    <v-list-item>
      <v-list-item-content v-if="isUserLoggedIn && networkOnLine">
        <v-list-item-title>
          <router-link to="/profile">Profile</router-link>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content v-if="isUserLoggedIn && networkOnLine">
        <v-list-item-title>
          <router-link to="/votes">Votes</router-link>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import firebase from 'firebase/app'
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState('authentication', ['authUser']),
    ...mapGetters('authentication', ['isUserLoggedIn']),
    ...mapGetters('app', ['newContentAvailable']),
    ...mapState('app', ['networkOnLine']),
  },
  methods: {
    async logout() {
      await firebase.auth().signOut()
    },
  },
}
</script>
