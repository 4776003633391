import state from './traits.state'
import mutations from './traits.mutations'
import actions from './traits.actions'
import getters from './traits.getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
