import _ from 'lodash'
import TraitsDb from '@/firebase/traits-db'
// import GenericDB from '@/firebase/generic-db'
// import RealtimeDb from '@/firebase/rt-db'

export default {
  /**
   * Get the available traits
   */
  getAvailableTraits: async ({ commit }) => {
    const traitDb = new TraitsDb()
    await traitDb.attachToRoot(data => {
      commit('setAvailableTraits', _.sortBy(data, ['name']))
    })
  },

  // syncTraits: async () => {
  //   const staticDb = new GenericDB('/traits')
  //   const dynDb = new RealtimeDb('/traits')

  //   const all = await staticDb.readAll()
  //   const ups = {}
  //   all.map(trait => (ups[`/traits/${trait.id}`] = trait))
  //   await dynDb.update(ups)
  // },
}
