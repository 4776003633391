export default {
  /* User input name */
  setUserNameToCreate: (state, userNameToCreate) => (state.userNameToCreate = userNameToCreate),

  /* Users */
  setRatingUser: (state, user) => {
    return (state.ratingUser = user)
  },
  addUser: (state, user) => state.users.push(user),
  removeUserById: (state, userId) => {
    const index = state.users.findIndex(user => user.id === userId)
    state.users.splice(index, 1)
  },
  /* Users deletion */
  addUserDeletionPending: (state, userId) => state.userDeletionPending.push(userId),
  removeUserDeletionPending: (state, userId) => {
    const index = state.users.findIndex(user => user.id === userId)
    state.userDeletionPending.splice(index, 1)
  },

  /* User creation */
  setUserCreationPending: (state, value) => (state.userCreationPending = value),
}
