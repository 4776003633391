import Vue from 'vue'
import Router from 'vue-router'
import Head from 'vue-head'
import Home from '@/views/Home'
import CheckLogin from '@/views/CheckLogin'
import { isNil } from 'lodash'
import store from '@/store'

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE,
})

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/check-login',
      name: 'check-login',
      component: CheckLogin,
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "client-chunk-login" */ '@/views/Login.vue'),
      meta: {
        authNotRequired: true,
      },
    },
    {
      path: '/rate',
      name: 'ratelist',
      component: () => import(/* webpackChunkName: "client-chunk-products" */ '@/views/Rate.vue'),
    },
    {
      path: '/rate/:id',
      name: 'rate',
      component: () => import(/* webpackChunkName: "client-chunk-products" */ '@/views/Rate.vue'),
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import(/* webpackChunkName: "client-chunk-products" */ '@/views/Profile.vue'),
    },
    {
      path: '/votes',
      name: 'votes',
      component: () => import(/* webpackChunkName: "client-chunk-products" */ '@/views/Votes.vue'),
    },
    { path: '*', redirect: '/home' },
  ],
})

/**
 * Handle user redirections
 */
router.beforeEach((to, from, next) => {
  if (!(to.meta && to.meta.authNotRequired) && isNil(store.state.authentication.authUser)) {
    const path = store.state.authentication.authUser === null ? '/login' : '/check-login'
    return next(`${path}?redirectUrl=${to.path}`)
  }
  next()
})

export default router
