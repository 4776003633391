export default {
  /* Trait input name */
  setTraitNameToCreate: (state, traitNameToCreate) => (state.traitNameToCreate = traitNameToCreate),

  /* Traits */
  setTraits: (state, traits) => (state.traits = traits),
  setAvailableTraits: (state, traits) => (state.availableTraits = traits),
  addTrait: (state, trait) => state.traits.push(trait),
  removeTraitById: (state, traitId) => {
    const index = state.traits.findIndex(trait => trait.id === traitId)
    state.traits.splice(index, 1)
  },

  /* Traits deletion */
  addTraitDeletionPending: (state, traitId) => state.traitDeletionPending.push(traitId),
  removeTraitDeletionPending: (state, traitId) => {
    const index = state.traits.findIndex(trait => trait.id === traitId)
    state.traitDeletionPending.splice(index, 1)
  },

  /* Trait creation */
  setTraitCreationPending: (state, value) => (state.traitCreationPending = value),
}
